/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState } from "react"
import html2canvas from 'html2canvas'
import { DotLoading, Loading, SpinLoading, Toast } from 'antd-mobile'

import { Popup } from 'antd-mobile'
import { copyTextToClipboard, setGoogleTagEvent } from "src/utils"
import { NewYearShareContent2x, NewYearShareFooter2x } from "../../static/shareImage"
import QrCodeDraw from './QrCodeDraw'
import { Icons, actionType } from "./config"

import './img.worker.js'

import styles from './index.module.scss'

const isDev = false;
const allowDownload = false;


function downloadImage(data: string, filename: string) {
  const a = document.createElement('a');
  a.href = data;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

function imgToDataURL(imgElement: HTMLImageElement, callback: (url: any) => void) {
  const canvas = document.createElement('canvas');
  const width = imgElement.naturalWidth;
  const height = imgElement.naturalHeight;
  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext('2d') as any;
  // const dpr = window.devicePixelRatio || 1;

  ctx.drawImage(imgElement, 0, 0, width, height);
  callback(canvas.toDataURL('image/png', 0.92))
  // const imageData = ctx.getImageData(0, 0, width, height);
  // const worker = new Worker(new URL('./img.worker.js', import.meta.url))
  // worker.postMessage({ imageData: imageData, dpr });
  // worker.onmessage = function (e) {
  //   callback(e.data);
  // };
}


const SharingItems = ({ onClick }: { onClick: (item: typeof Icons[number]) => void }) => {
  return <div className={styles.iconWrapper}>
    {Icons.map((item) => {
      const { text, icon } = item

      return <div key={text} className={styles.icon} onClick={() => onClick(item)}>
        <img src={icon} />
        <div className={styles.imageLabel}>{text}</div>
      </div>
    })}
  </div>
}

export const Share = React.memo(({ onCancel, show, text = '' }: { show: boolean, text?: string, onCancel?: () => void }) => {
  const shareRef = useRef<HTMLDivElement>(null)
  const rootRef = useRef<HTMLDivElement>(null)
  const resultRef = useRef<HTMLDivElement>(null)

  const [imgLoaded, setImgLoad] = useState({ bgImg: false, footer: false, qrcode: false })
  const [dataUrl, setDataUrl] = useState({ bgImg: '', footer: '' })
  const [sharingCard, setSharingCard] = useState('')

  const downloadShareCard = () => {
    return shareRef.current &&
      html2canvas(shareRef.current, { backgroundColor: null }).then(function (canvas) {
        const imgData = canvas.toDataURL('image/png');
        if (allowDownload) {
          downloadImage(imgData, 'screenshot.jpg');
        }
        return imgData
      })
  }

  useEffect(() => {
    let t = -1
    if (imgLoaded.bgImg && !imgLoaded.footer && imgLoaded.qrcode && show && !sharingCard) {
      // eslint-disable-next-line no-alert
      // qrcode generate faster than download the images,
      t = window.setTimeout(() => {
        downloadShareCard()?.then((data: string) => {
          window.BossJobApp?.shareImageData?.(data)
          setSharingCard(data)
        })
      }, 600);
    }
    return () => clearTimeout(t)
  }, [imgLoaded, show, sharingCard])

  const copyUrl = (text: string) => {
    return (copyTextToClipboard(text))
      .then(() => {
        Toast.show({
          content: <div style={{ whiteSpace: 'nowrap' }}>sharing url has been copied</div>
        })
      }).catch((error: any) => {
        console.log(error)
        // eslint-disable-next-line no-alert
        alert(error)
      })
  }

  const openApp = (url: string) => {
    (window as any).location = url
  }
  const onActionClick = (item: typeof Icons[number]) => {
    const { type, name, url, gtmEventName } = item
    try {
      setGoogleTagEvent(gtmEventName)
      if (type === actionType.download) {
        // downloadShareCard()
        window.BossJobApp?.downloadImage?.()
        return
      }
      if (type === actionType.copy) {
        window.BossJobApp?.copyLink?.(text)   // copyUrl(text)
        return
      }
      if (url) {
        if (typeof url === 'string') {
          openApp(url)
        } else if (typeof url === 'function') {
          name && openApp(url({ app: name, url: text }))
        }
        // copyUrl(text).then(() => {
        //   // display a toast to indicate url is copied
        //   if (typeof url === 'string') {
        //     openApp(url)
        //   } else if (typeof url === 'function') {
        //     name && openApp(url({ app: name, url: text }))
        //   }
        // })
        return
      }
    } catch (error) {
      console.error('there is a error occurred, please fix it,', error)
    }
  }

  const stopScroll = (e: React.UIEvent) => {
    e.stopPropagation()
    // e.preventDefault()
  }
  const Content = () => {
    return <div className={styles.shareRoot}
      ref={rootRef}
      onTouchMove={stopScroll}
      onClick={(e) => {
        if ([rootRef.current, resultRef.current].includes(e.target as any)) {
          onCancel?.()
        }
      }}>
      <div
        className={styles.imgResult}
        ref={resultRef}
      >
        {sharingCard && <img style={{ borderRadius: 12 }} src={`${sharingCard}`} />}
        {!sharingCard && <div className={styles.loadingWrapper}>
          <div className="loading-container" style={{ position: 'static', transform: 'none' }}>
            <div className="loading-spinner"></div>
          </div>
          {/* <span>Loading<DotLoading /></span> */}
        </div>}
      </div>
      <div className={styles.bottomActionWrapper}>
        <div className={styles.iconRoot} style={{ overflow: 'auto' }} >
          <SharingItems onClick={onActionClick} />
        </div>
        <div style={{ padding: '0 16px', width: '100%', boxSizing: 'border-box' }}>
          <button className={styles.cancelBtn} onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div >
  }

  return <>
    <div className={styles.shareRoot}
      id="previewContent"
      style={!isDev ? { position: 'fixed', left: "-1000%", top: "-1000%", zIndex: -1 } : {}}
    >
      <div className={styles.shareCard} ref={shareRef}>
        <img
          crossOrigin="anonymous"
          src={dataUrl.bgImg || NewYearShareContent2x}
          onLoad={
            !dataUrl.bgImg ?
              (event: any) => {
                imgToDataURL(event.target, (data) => {
                  setDataUrl(pre => ({ ...pre, bgImg: data }))
                  setImgLoad(pre => ({ ...pre, bgImg: true }))
                })
              }
              : undefined
          }
        />
        <div className={styles.content}>
          {/* <img
            crossOrigin="anonymous"
            src={dataUrl.footer || NewYearShareFooter2x}
            onLoad={!dataUrl.footer ?
              (event: any) => {
                imgToDataURL(event.target, (data) => {
                  setDataUrl(pre => ({ ...pre, footer: data }))
                  setImgLoad(pre => ({ ...pre, footer: true }))
                })
              } : undefined
            }
          /> */}
          {
            <div className={styles.bottom}>
              <div className={styles.text}
                style={{ display: 'flex', flexDirection: 'column' }}>
                {/* Use my link to sign up and get a chance to win the Angpao Lucky Draw (worth $1,888+)! */}
                <span style={{ lineHeight: 1, marginBottom: 8 }}>
                  Join <span style={{ color: '#2378E5' }}>Bossjob</span> with me
                </span>
                <div style={{ fontSize: 12, fontWeight: 400 }}>
                  Scan the QR Code to sign up and get 1 chance in the lucky draw
                </div>
              </div>

              <div className={styles.QRCodeWrapper}>
                <QrCodeDraw text={text} ecl="H" width={'100%'} height={'100%'} onLoad={() => setImgLoad((pre) => ({ ...pre, qrcode: true }))} />
              </div>
            </div>
          }
        </div>
      </div>
    </div >
    <Popup
      maskStyle={{ backgroundColor: 'transparent' }}
      bodyStyle={{
        height: '100vh',
        backgroundColor: 'transparent'
      }}
      visible={show}
      onMaskClick={onCancel}
      onClose={onCancel}
    >
      <Content />
    </Popup>
  </>
})