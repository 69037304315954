import moment from 'moment'
import { useEffect, useState } from 'react'
import { Toast } from 'antd-mobile'

const TIME = '2024-02-25 23:59:59'

const useSgShare = (ended_at: string) => {
  const [currentTime, setCurrentTime] = useState(moment())
  const [showShare, setShowShare] = useState(false)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(moment())
    }, 30000)

    return () => clearInterval(intervalId)
  }, [])

  const targetDate = moment(ended_at || TIME)
  const isAfterTargetDate = currentTime.isAfter(targetDate)

  const handleShowShareModelContentBtn = () => {
    if (!isAfterTargetDate) {
      setShowShare(true)
    } else {
      Toast.show({
        content: 'Waiting for the lottery draw on the 27th'
      })
    }
  }

  const handleShowShareModel = () => {
    setShowShare(true)
  }

  return {
    showShare,
    setShowShare,

    isAfterTargetDate,
    handleShowShareModel,
    handleShowShareModelContentBtn
  }
}

export default useSgShare
