import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'
// default config

import enLanguage from '/src/crowdin/locales/en-US/en-US.json'
import zhLanguage from '/src/crowdin/locales/zh-CN/zh-CN.json'
import idLanguage from '/src/crowdin/locales/id-ID/id-ID.json'
import jpLanguage from '/src/crowdin/locales/ja-JP/ja-JP.json'
import zhTWLanguage from '/src/crowdin/locales/zh-TW/zh-TW.json'

const showI18nButton = () => {
  var button = document.createElement('div')
  button.style.cssText = `
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 888;
    cursor: pointer;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    color: white;
    background-color: red;
    text-align: center;
    line-height: 50px;
    background-color: red;
    opacity: 0.5;
  `
  button.innerText = 'i18n'
  document.body.appendChild(button)
}

export const resources = {
  en: { translation: { ...enLanguage } },
  'zh-CN': { translation: { ...zhLanguage } },
  id: { translation: { ...idLanguage } },
  ja: { translation: { ...jpLanguage } },
  'zh-TW': { translation: { ...zhTWLanguage } }
}

i18next
  .use(I18nextBrowserLanguageDetector) //根据用户浏览器 语言进行配置
  .use(initReactI18next)
  // .use(i18nextPlugin) //?showtranslations
  .init(
    {
      //lng: 'en-US', // Default language
      debug: true,
      fallbackLng: 'en', // Error callback default language
      interpolation: {
        escapeValue: false
      },
      resources,
      detection: {
        order: [
          'querystring',
          // this App is not support language path, so we should remove it, otherwise it can cause many problem
          // 'path',
          'cookie',
          'localStorage'
        ],
        lookupCookie: 'i18next',
        lookupQuerystring: 'language',

        // cache user language on
        caches: ['localStorage', 'cookie'],
        excludeCacheFor: ['cimode']
      }
    },
    (error) => {
      if (error) {
        i18next.reloadResources()
        //
        return console.log('Language something went wrong loading')
      }
    }
  )

i18next.on('failedLoading', function (lng, ns, msg) {
  message.warning(msg)
  setTimeout(() => location.reload(), 1500)
})

export default i18next

/**
  @author Lee
  @description const { t } = useTranslation(['settings', 'defConfig']); t('settings:tab1') 通过命名空间调用
  @description document https://www.i18next.com/translation-function/interpolation
*/
