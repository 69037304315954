
import { lazy } from 'react'
import { Navigate, RouteObject } from 'react-router-dom'

// App FQA
const Faq = lazy(() => import('pages/faq'))
const GeneralFAQ = lazy(() => import('pages/faq/general'))
const BossFAQ = lazy(() => import('pages/faq/boss'))
const TalentsFQA = lazy(() => import('pages/faq/talents'))

// USDT GIVEAWAY
const Home = lazy(() => import('pages/home'))
const PrivacyPolicy = lazy(() => import('pages/privacy-policy'))
const TermsAndConditions = lazy(() => import('pages/terms-and-conditions'))
const USDTGiveaway = lazy(() => import('pages/product-launch-party/100USDT_Giveaway'))
const USDTGiveawayRule = lazy(() => import('pages/product-launch-party/100USDT_Giveaway_Rule'))

// NEW YEAR BOOST CAMPAIGN
import InviteCampaignHome from 'pages/new-year-boost-campaign/index'

// SG Product
import AwardSubmit from 'pages/new-year-boost-campaign/award/submit'
import AwardSuccess from 'pages/new-year-boost-campaign/award/success'
import { Jump } from 'pages/jump'

const Login = lazy(() => import('pages/new-year-boost-campaign/login'))
const RouterConfig: RouteObject[] = [
  {
    path: '/home',
    element: <Home />
  },
  {
    path: '/faq',
    element: <Faq />
  },
  {
    path: '/faq/general',
    element: <GeneralFAQ />
  },
  {
    path: '/faq/boss',
    element: <BossFAQ />
  },
  {
    path: '/faq/talents',
    element: <TalentsFQA />
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />
  },
  {
    path: '/terms-Conditions',
    element: <TermsAndConditions />
  },
  {
    path: '/product-launch-party',
    children: [
      {
        path: 'USDT-Giveaway',
        element: <USDTGiveaway />
      },
      {
        path: 'USDT-Giveaway-Rule',
        element: <USDTGiveawayRule />
      }
    ]
  },
  {
    path: "/new-year-boost-campaign",
    children: [
      {
        path: 'home',
        element: <InviteCampaignHome />
      },
      {
        path: ':id/submit',
        element: <AwardSubmit />
      },
      {
        path: ':id/success',
        element: <AwardSuccess />
      },
      {
        path: 'login',
        element: <Login />
      }
    ]

  },
  {
    path: '/Ju',
    element: <Jump />
  },
  {
    path: '/',
    element: <Navigate to='/home' />
  }

]

export default RouterConfig
