import React, { useEffect, useRef, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import Lottie from 'lottie-web'
import { SpinLoading } from 'antd-mobile'

import awardSuccessJson from '../static/json/awarded-success.json'
import { getAwardConfig } from './config'
import Rules from './Rules'
import styles from './index.module.scss'
import { setGoogleTagEvent } from 'src/utils'
import { rewardEvents } from '../events'
import { refreshTokenServer } from 'src/lib/server'
import { getCookie } from 'src/utils/cookies'

const minTime = 500 // ms

const AwardSuccess = () => {
  const container = useRef<any>(null)
  const [loading, setLoading] = useState(true)

  const params: any = useParams()
  const { message, image } = getAwardConfig(params?.id)

  const [searchParams] = useSearchParams()
  const email = searchParams.get('email') || ''

  useEffect(() => {
    const startTime = new Date().getTime()
    const animation = Lottie.loadAnimation({
      name: 'sg-awarded-success',
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: awardSuccessJson
    })
    setGoogleTagEvent(rewardEvents.pageView.name)

    animation.addEventListener('DOMLoaded', () => {
      const endTime = new Date().getTime()
      const diffTime = endTime - startTime
      setTimeout(
        () => {
          setLoading(false)
        },
        diffTime > minTime ? 0 : minTime
      )
    })

    return () => {
      Lottie.destroy('sg-awarded-success')
    }
  }, [])

  return (
    <>
      {loading && (
        <div className={styles.pageLoading}>
          <SpinLoading color='primary' />
        </div>
      )}
      <div className={styles.main + ' ' + styles.last + ' ' + (loading ? styles.hide : '')}>
        <Rules />
        <div ref={container} className={styles.animation}></div>
        <div className={styles.box}>
          <div className={styles.content}>
            {/* image */}
            <div className={styles.giftBox}>
              <img src={image} alt='award' />
            </div>
            {/* gift message */}
            <div className={styles.giftInfo}>{message}</div>
            {/* form */}
            <div className={styles.formView}>
              <p>Email:</p>
              <p>{email}</p>
            </div>
            {/* tip */}
            <div className={styles.tip}>Bossjob will contact you within 2-5 working days</div>
          </div>
        </div>
      </div>
    </>
  )
}

// export default AwardSuccess

const Wrapper = () => {
  const [isShowMe, setIsShowMe] = useState<boolean>(false)

  useEffect(() => {
    const { BossJobApp } = window
    const isApp = getCookie('appDeviceUUID') || BossJobApp?.getRefreshToken?.()
    if (!isApp) {
      setIsShowMe(true)
      return
    }

    refreshTokenServer()
      .then(() => {
        setIsShowMe(true)
      })
      .catch((error: string) => {
        console.log(error)
      })
  }, [])

  return isShowMe ? <AwardSuccess /> : null
}

export default Wrapper
