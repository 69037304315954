/*
 * @Author: Lee
 * @Date: 2023-06-22 12:52:05
 * @LastEditTime: 2023-07-02 10:30:14
 * @LastEditors: Lee
 */
import axios from 'axios'
import { isIOS, isAndroid } from 'react-device-detect'
import { getCookie, refreshToken, removeCookie, setAccessToken, setCookie } from 'src/utils/cookies'
// import qs from 'qs'

// 兼容IOS
let globalToken: any = null

const serverConfig = {
  baseURL: process.env.REACT_APP_S3_AUTH_BOSSJOB_URL,
  useTokenAuthorization: true, // 是否开启 token 认证
  timeout: 1000 * 60 * 5
}
const lockKey = 'data-batchId'
let blockPromise: any = undefined
const refreshTokenServer = () => {
  if (blockPromise) {
    return blockPromise
  }

  const configuredAxios = axios.create({
    baseURL: serverConfig.baseURL,
    timeout: serverConfig.timeout,
    withCredentials: false // 跨域请求是否需要携带 cookie
  })
  // const refresh = getCookie(refreshToken)
  const refresh = getAppInRefreshToken()
  const data = { source: 'web', refresh: refresh }

  if (isIOS) {
    data.source = 'app_ios'
  }

  if (isAndroid) {
    data.source = 'app_android'
  }

  return (blockPromise = configuredAxios
    .post('/authentication/token/refresh', data)
    .then((res) => {
      const { access, token_expired_at, data } = res.data.data
      if (access) {
        setAccessToken(access, token_expired_at)
        globalToken = access
        window.BossJobH5Token = access
        return access
      }
      // if goes in this statement, the data is 'refreshtoken expiration of identity'
      return Promise.reject(new Error(data))
    })
    .finally(() => {
      setTimeout(() => {
        blockPromise = undefined
      }, 1000 * 60 * 2)
    }))
}

//兼容ios获取refresh Token
const getAppInRefreshToken = () => {
  const { BossJobApp } = window
  // Not IOS
  if (!isIOS) {
    return getCookie(refreshToken)
  }

  //IOS
  try {
    if (BossJobApp && BossJobApp?.getRefreshToken) {
      const refreshToken = BossJobApp?.getRefreshToken()
      if (refreshToken && refreshToken?.length) {
        setCookie('refreshToken', refreshToken, false)
        return refreshToken
      } else {
        return getCookie(refreshToken)
      }
    } else {
      return getCookie(refreshToken)
    }
  } catch (error) {
    return getCookie(refreshToken)
  }
}

// 创建 axios 请求实例
const serviceAxios = axios.create({
  baseURL: serverConfig.baseURL, // 基础请求地址
  timeout: serverConfig.timeout, // 请求超时设置
  withCredentials: false // 跨域请求是否需要携带 cookie
})

// 创建请求拦截
serviceAxios.interceptors.request.use(
  (config: any) => {
    // 如果开启 token 认证
    const token = window.BossJobH5Token || getCookie('accessToken') || getCookie('token')
    if (serverConfig.useTokenAuthorization && token) {
      config.headers['Authorization'] = `Bearer ${token}` // 请求头携带 token
    }
    // 设置请求头
    if (!config.headers['Content-Type']) {
      // 如果没有设置请求头
      // if (config.method === 'post') {
      //   config.headers['content-type'] = 'application/x-www-form-urlencoded' // post 请求
      //   config.data = qs.stringify(config.data) // 序列化,比如表单数据
      // } else {
      // }
      config.headers['Content-Type'] = 'application/json' // 默认类型
    }
    // console.log('request config', config)
    return config
  },
  (error: any) => {
    Promise.reject(error)
  }
)

// 创建响应拦截
serviceAxios.interceptors.response.use(
  (res: any) => {
    const data = res.data
    // 处理自己的业务逻辑，比如判断 token 是否过期等等
    // 代码块
    return data
  },
  (error: any, ...res) => {
    let message = ''
    // debugger
    if (error && error.response) {
      switch (error.response.status) {
        case 302:
          message = '接口重定向了！'
          break
        case 400:
          message = '参数不正确！'
          break
        case 401: {
          message = '您未登录，或者登录已经超时，请先登录！'
          const currentBatchId = `${error.config[`${lockKey}`]}`
          // removeCookie('token')
          // removeCookie('userInfo')
          // window.location.pathname = '/login'
          if ((currentBatchId as string).includes('used')) {
            return Promise.reject(error)
          } else {
            return refreshTokenServer()
              .then((token: any) => {
                // tracker
                error.config[`${lockKey}`] = `used`
                // we don't need to modify the Authorization in config.headers
                // request.use will configure it again
                return serviceAxios(error.config)
              })
              .catch(() => {
                return Promise.reject(error)
              })
          }
        }
        case 403:
          message = '您没有权限操作！'
          break
        case 404:
          message = `请求地址出错: ${error.response.config.url}`
          break
        case 408:
          message = '请求超时！'
          break
        case 409:
          message = '系统已存在相同数据！'
          break
        case 500:
          message = '服务器内部错误！'
          break
        case 501:
          message = '服务未实现！'
          break
        case 502:
          message = '网关错误！'
          break
        case 503:
          message = '服务不可用！'
          break
        case 504:
          message = '服务暂时无法访问，请稍后再试！'
          break
        case 505:
          message = 'HTTP 版本不受支持！'
          break
        default:
          message = '异常问题，请联系管理员！'
          break
      }
    }
    return Promise.reject({ response: error, message })
  }
)
export default serviceAxios

export { refreshTokenServer }
