import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { setCookie } from './cookies'
import {
  HunDefaultAvatar1,
  HunDefaultAvatar2,
  HunDefaultAvatar3,
  HunDefaultAvatar4,
  HunDefaultAvatar5
} from 'src/images'

// 判断客户端
const judgeClient = () => {
  const u = navigator.userAgent
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
  const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  if (isAndroid) {
    return 'Android'
  } else if (isIOS) {
    return 'Ios'
  } else {
    return 'Web'
  }
}

// 获取url中的参数。如果是数组的话需要自己选择
function getURLParameters(url: string): Record<string, string | string[]> {
  const params: Record<string, string | string[]> = {}
  const queryString = url.split('?')[1]

  if (!queryString) return params

  const keyValuePairs = queryString.split('&')

  for (let i = 0; i < keyValuePairs.length; i++) {
    const keyValuePair = keyValuePairs[i].split('=')
    const key = decodeURIComponent(keyValuePair[0])
    let value: string | string[] = decodeURIComponent(keyValuePair[1])

    // Handle case where value may contain encoded '=' character
    if (keyValuePair.length > 2) {
      value = keyValuePair.slice(1).join('=')
    }

    // Handle case where key exists more than once
    if (params[key]) {
      if (!Array.isArray(params[key])) {
        params[key] = [params[key] as string]
      }
      ; (params[key] as string[]).push(value)
    } else {
      params[key] = value
    }
  }

  return params
}

// 些死的国家
const allSupportedCountry = [
  {
    id: 167,
    key: 'ph',
    value: 'Philippines'
  },
  {
    id: 193,
    key: 'sg',
    value: 'Singapore'
  },
  {
    id: 96,
    key: 'id',
    value: 'Indonesia'
  },
  {
    id: 105,
    key: 'jp',
    value: 'Japan'
  },
  {
    id: 127,
    key: 'my',
    value: 'Malaysia'
  }
]

type timerType = {
  days: number
  hours: number
  minutes: number
  seconds: number
}

//将时间戳转为时间
function formatTimestamp(timestamp: number): timerType {
  const secondsPerMinute = 60
  const secondsPerHour = 3600
  const secondsPerDay = 86400

  // 计算天数
  const days: number = Math.floor(timestamp / secondsPerDay)
  timestamp %= secondsPerDay

  // 计算小时数
  const hours: number = Math.floor(timestamp / secondsPerHour)
  timestamp %= secondsPerHour

  // 计算分钟数
  const minutes: number = Math.floor(timestamp / secondsPerMinute)

  // 剩余的秒数
  const seconds: number = timestamp % secondsPerMinute

  // 返回格式化后的时间
  return {
    days,
    hours,
    minutes,
    seconds
  }
}

// 定义事件防抖函数
function debounce<T extends any[]>(func: (...args: T) => void, delay: number) {
  let timerId: ReturnType<typeof setTimeout>

  return function (this: any, ...args: T) {
    // 每次调用函数时，先清除之前设置的定时器
    clearTimeout(timerId)

    // 设置一个新的定时器，延迟执行函数
    timerId = setTimeout(() => {
      func.apply(this, args)
    }, delay)
  }
}

const TopListFalseData = [
  {
    avatar: HunDefaultAvatar5,
    exchanged_usdt: 790,
    masked_full_name: 'A**** g*********',
    rank: 1,
    used_point: 79000
  },
  {
    avatar: HunDefaultAvatar1,
    exchanged_usdt: 536,
    masked_full_name: 'J**** a********',
    rank: 2,
    used_point: 53600
  },
  {
    avatar: HunDefaultAvatar2,
    exchanged_usdt: 370,
    masked_full_name: 'H**** b************',
    rank: 3,
    used_point: 37000
  },
  {
    avatar: HunDefaultAvatar3,
    exchanged_usdt: 323,
    masked_full_name: 'A**** b******',
    rank: 4,
    used_point: 32300
  },
  {
    avatar: HunDefaultAvatar4,
    exchanged_usdt: 309,
    masked_full_name: 'B*** d************',
    rank: 5,
    used_point: 30900
  },
  {
    avatar: HunDefaultAvatar2,
    exchanged_usdt: 175,
    masked_full_name: 'L**** p********',
    rank: 6,
    used_point: 17500
  },
  {
    avatar: HunDefaultAvatar1,
    exchanged_usdt: 130,
    masked_full_name: 'J***** m********',
    rank: 7,
    used_point: 13000
  },
  {
    avatar: HunDefaultAvatar5,
    exchanged_usdt: 116,
    masked_full_name: 'R** j********',
    rank: 8,
    used_point: 11600
  },
  {
    avatar: HunDefaultAvatar3,
    exchanged_usdt: 90,
    masked_full_name: 'T**** b********',
    rank: 9,
    used_point: 9000
  },
  {
    avatar: HunDefaultAvatar2,
    exchanged_usdt: 66,
    masked_full_name: 'P*** b******',
    rank: 10,
    used_point: 6600
  }
]

const copyTextToClipboard = (text: string) => {

  if (!navigator?.clipboard?.writeText) {
    const copyMethod = () => new Promise<void>((res) => {
      const input = document.createElement('input')
      input.value = text
      document.body.append(input)

      input.style.position = 'absolute'
      input.style.opacity = '0'

      input.select();
      input.setSelectionRange(0, 99999);

      document.execCommand("copy");

      setTimeout(() => {
        document.body.removeChild(input)
      });
      res()
    })

    return copyMethod()
  }
  return navigator?.clipboard?.writeText(text)
}

export {
  judgeClient,
  getURLParameters,
  allSupportedCountry,
  formatTimestamp,
  debounce,
  TopListFalseData,
  copyTextToClipboard
}

export const formatNumber = (number: number) =>
  new Intl.NumberFormat('en-US', { style: 'decimal' }).format(number)

  export const getDeviceUuid = async () => {
    let deviceId = localStorage.getItem('uuid')
    if (!deviceId) {
        const fpPromise = FingerprintJS.load()
        const fp = await fpPromise
        const result = await fp.get()
        deviceId = result.visitorId
        localStorage.setItem('uuid', deviceId)
    }
    setCookie('uuid', deviceId,'')
    return deviceId
}

/**
 * Sets a Google Tag Manager event.
 *
 * @param {string} eventName - The name of the event.
 * @param {object} eventParams - Optional parameters for the event.
 * @return {Promise<void>} A promise that resolves when the event has been set.
 */
export const setGoogleTagEvent = (eventName: string, eventParams: object = {}) => {
  if((typeof window != 'undefined') && window?.gtag) {
    return Promise.resolve(window.gtag('event', eventName, eventParams))
  }
  return Promise.resolve()
}
