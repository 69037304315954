import React, { useEffect, useRef, useState } from 'react'
import Lottie from 'lottie-web'
import { useParams } from 'react-router-dom'
import { SpinLoading } from 'antd-mobile'

import awardSubmitJson from '../static/json/awarded-congrats.json'
import { checkAwardId, getAwardConfig } from './config'
import FormSubmit from './Form'
import Rules from './Rules'
import styles from './index.module.scss'
import { setGoogleTagEvent } from 'src/utils'
import { rewardEvents } from '../events'
import { refreshTokenServer } from 'src/lib/server'
import { getCookie } from 'src/utils/cookies'

const minTime = 500 // ms

const AwardSubmit = () => {
  const container = useRef<any>(null)
  const params: any = useParams()
  const [loading, setLoading] = useState(true)

  const isValidId = checkAwardId(params?.id)

  const { title, message, image } = getAwardConfig(params?.id)

  useEffect(() => {
    const startTime = new Date().getTime()
    const animation = Lottie.loadAnimation({
      name: 'sg-awarded-congrats',
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: awardSubmitJson
    })
    setGoogleTagEvent(rewardEvents.pageView.name)

    animation.addEventListener('DOMLoaded', () => {
      const endTime = new Date().getTime()
      const diffTime = endTime - startTime

      setTimeout(
        () => {
          setLoading(false)
        },
        diffTime > minTime ? 0 : minTime
      )
    })

    return () => {
      Lottie.destroy('sg-awarded-congrats')
    }
  }, [])

  return (
    <>
      {loading && (
        <div className={styles.pageLoading}>
          <SpinLoading color='primary' />
        </div>
      )}
      <div className={styles.main + ' ' + (loading ? styles.hide : '')}>
        <Rules />
        <div ref={container} className={styles.animation}></div>
        <div className={styles.box}>
          {isValidId ? (
            <div className={styles.content}>
              {/* title */}
              <div className={styles.title}>
                <p>BOSS SAYS, </p>
                <p>{title}</p>
              </div>
              {/* images */}
              <div className={styles.giftBox}>
                <img src={image} alt='award' />
              </div>
              {/* gift message */}
              <div className={styles.giftInfo}>{message}</div>
              {/* form */}
              <FormSubmit id={params?.id} />
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}

// export default AwardSubmit

const Wrapper = () => {
  const [isShowMe, setIsShowMe] = useState<boolean>(false)

  useEffect(() => {
    const { BossJobApp } = window
    const isApp = getCookie('appDeviceUUID') || BossJobApp?.getRefreshToken?.()
    if (!isApp) {
      setIsShowMe(true)
      return
    }

    refreshTokenServer()
      .then(() => {
        setIsShowMe(true)
      })
      .catch((error: string) => {
        console.log(error)
      })
  }, [])

  return isShowMe ? <AwardSubmit /> : null
}

export default Wrapper
