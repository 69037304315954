import React, { useEffect } from 'react'
import styled from 'styled-components'

export default function ReferWinPopup({ open, onClose, userName, acceptCallback }: any) {
  useEffect(() => {
    const scrollY = document.body.style.top;

    if (open) {
      document.body.style.position = 'fixed';
      document.body.style.top = '0';
      document.body.style.bottom = '0';
      document.body.style.left = '0';
      document.body.style.right = '0';
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.position = 'static';
      document.body.style.overflow = 'auto';
      document.body.style.top = scrollY;
    }

  }, [open])

  if (open) {
    return (
      <ReferWinPopupWrapped>
        <div className='dialog'>
          <svg
            className='close'
            xmlns='http://www.w3.org/2000/svg'
            width='33'
            height='33'
            fill='none'
            onClick={onClose}
          >
            <rect
              x='1.5'
              y='1.5'
              width='30'
              height='30'
              rx='15'
              fill='url(#paint0_linear_771_24115)'
              stroke='white'
              strokeWidth='2.19512'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M22.7633 11.7882C23.192 11.3596 23.192 10.6646 22.7633 10.236C22.3347 9.80739 21.6398 9.80739 21.2111 10.236L16.4994 14.9478L11.7877 10.2362C11.3591 9.80753 10.6642 9.80753 10.2355 10.2362C9.80691 10.6648 9.80691 11.3597 10.2355 11.7883L14.9472 16.5L10.2355 21.2116C9.80691 21.6403 9.80691 22.3352 10.2355 22.7638C10.6642 23.1924 11.3591 23.1924 11.7877 22.7638L16.4994 18.0522L21.2111 22.764C21.6398 23.1926 22.3347 23.1926 22.7633 22.764C23.192 22.3353 23.192 21.6404 22.7633 21.2118L18.0515 16.5L22.7633 11.7882Z'
              fill='white'
            />
            <defs>
              <linearGradient
                id='paint0_linear_771_24115'
                x1='31.5'
                y1='51.622'
                x2='-0.791325'
                y2='43.7677'
                gradientUnits='userSpaceOnUse'
              >
                <stop stopColor='#FFD85A' />
                <stop offset='1' stopColor='#FF0A54' />
              </linearGradient>
            </defs>
          </svg>
          <p className="text">
            You were invited by
            <span>{userName}</span>
          </p>
          <img className='lucky' src='/AngpaoLucky.png' alt='' />
          <span className='btn' onClick={acceptCallback} />
        </div>
      </ReferWinPopupWrapped>
    )
  }
  return null
}

const ReferWinPopupWrapped = styled.div`
  z-index: 5000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  .dialog {
    position: relative;
    width: 308px;
    height: 398px;
    background-image: url('/referwin_bg.png');
    background-size: 100% 100%;
    .text {
      padding-top: 95px;
      text-align: center;
      font-size: 24px;
      color: #fff;
      font-weight: 700;
      span {
        display: inline-block;
        width: 70%;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        color:#FFDA86
      }
    }
    .close {
      position: absolute;
      right: -4px;
      top: 8px;
      cursor: pointer;
    }
    .lucky {
      position: absolute;
      bottom: 0;
      left: -10px;
      width:342px;
      height: 226px;
    }
    .btn {
      position: absolute;
      bottom: -13px;
      left: 50%;
      margin-left: -96px;
      width: 192px;
      height: 60px;
      font-weight: 700;
      font-size: 20px;
      overflow: hidden;
      background-image: url('/accept_btn_bg.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }
`
