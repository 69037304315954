const S3BucketJobseeker = process.env.REACT_APP_S3_BUCKET_URL
const S3BucketBossHunt = process.env.REACT_APP_S3_AUTH_BOSSHUNT_URL
export const Avatar = `${S3BucketJobseeker}/Avatar.png`

export const CompetitionBg = `${S3BucketJobseeker}/CompetitionBg.png`
export const DailyTaskBg = `${S3BucketJobseeker}/DailyTaskBg.png`
export const InviteToWinUSDT = `${S3BucketJobseeker}/Invite-to-win-USDT.png`
export const LeaderboardBg = `${S3BucketJobseeker}/LeaderboardBg.png`
export const LogoBluerColor = `${S3BucketJobseeker}/Logo-bluer-color.svg`
export const NaverTaskBg = `${S3BucketJobseeker}/naverTaskBg.png`
export const TopListGb = `${S3BucketJobseeker}/TopListGb.png`
export const TopListOne = `${S3BucketJobseeker}/TopListOne.png`
export const TopListThree = `${S3BucketJobseeker}/TopListThree.png`

export const TopListTwo = `${S3BucketJobseeker}/TopListTwo.png`
export const USDTGiveawayHead = `${S3BucketJobseeker}/USDTGiveawayHead.png`
export const USDTGiveawayInvite = `${S3BucketJobseeker}/USDTGiveawayInvite.png`

export const USDT1000001x = `${S3BucketJobseeker}/New100000USDT1x.png`
export const USDT1000002x = `${S3BucketJobseeker}/New100000USDT2x.png`

export const NewChampion = `${S3BucketJobseeker}/NewChampion.png`
export const NewTopListBg = `${S3BucketJobseeker}/NewTopListBg.png`

// 默认头像
export const HunDefaultAvatar5 = `${S3BucketBossHunt}/HunDefaultAvatar5.png`
export const HunDefaultAvatar1 = `${S3BucketBossHunt}/HunDefaultAvatar1.png`
export const HunDefaultAvatar2 = `${S3BucketBossHunt}/HunDefaultAvatar2.png`
export const HunDefaultAvatar3 = `${S3BucketBossHunt}/HunDefaultAvatar3.png`
export const HunDefaultAvatar4 = `${S3BucketBossHunt}/HunDefaultAvatar4.png`

// New year campaign
export const NewYearBanner2x = `${S3BucketJobseeker}/banner2x.png`
export const NewYearBanner = `${S3BucketJobseeker}/banner.png`
export const NewYearChanceBg = `${S3BucketJobseeker}/chanceBg.png`
export const NewYearBtnBg = `${S3BucketJobseeker}/btnBg.png`
export const NewYearBtnDisabled = `${S3BucketJobseeker}/NewYearbtnDisabled.png`
export const NewYearWhenNewYearInviteStart = `${S3BucketJobseeker}/whenNewYearInviteStart.png`
export const NewYearTicket = `${S3BucketJobseeker}/ticket.png`
export const NewYearSubtract = `${S3BucketJobseeker}/Subtract.png`
export const NewYearHand = `${S3BucketJobseeker}/hand.png`
export const NewYearEmptyStateIcon = `${S3BucketJobseeker}/emptyStateIcon.png`

export const awardGoldImage = `${S3BucketJobseeker}/award-gold.png`
export const awardIkeaImage = `${S3BucketJobseeker}/award-ikea.png`
export const awardUniqloImage = `${S3BucketJobseeker}/award-uniqlo.png`
export const awardStarbucksImage = `${S3BucketJobseeker}/award-starbucks.png`
export const awardVoucherImage = `${S3BucketJobseeker}/award-voucher.png`

export const NewYearStartBg = `${S3BucketJobseeker}/starbg.png`
