import { sharedModalEvents } from "pages/new-year-boost-campaign/events";
import { ShareIcons, content, footer } from "../../static/shareImage"

const facebookAppUrl = 'fb://feed';//   'https://www.facebook.com/'
// fb://page/{#pageid} android
const instagram = 'https://www.instagram.com' // only this https url can open IG    //'instagram://user'
const twitter = 'twitter://user' // "https://twitter.com/"// `twitter://user`
const reddit = 'https://www.reddit.com' // only this can open reddit
const whatsapp = 'https://wa.me'
const fbMessenger = 'https://m.me'  //'fb-messenger://user-thread'
const Telegram = 'https://t.me/chat' //'tg://msg' // 

export enum actionType {
  download,
  copy,
  openApp
}
const generateAppSchema = ({ app, url }: { app: string, url: string }) => {
  return `appshare://${app}?shareUrl=${url}`
}
export const Icons = [
  {
    text: 'Download',
    icon: ShareIcons.down,
    type: actionType.download,
    gtmEventName: sharedModalEvents.download.name
  },
  {
    text: 'Copy Link',
    icon: ShareIcons.link,
    type: actionType.copy,
    gtmEventName: sharedModalEvents.copyLink.name
  },
  {
    text: 'Whatsapp',
    icon: ShareIcons.whatsApp,
    name: 'whatsapp',
    url: generateAppSchema, // whatsapp
    gtmEventName: sharedModalEvents.whatsapp.name
  },
  {
    text: 'FB Messenger',
    icon: ShareIcons.FBMessage,
    name: 'fbmessenger',
    url: generateAppSchema, // fbMessenger
    gtmEventName: sharedModalEvents.fbMessenger.name
  },
  // {
  //   text: 'Telegram',
  //   icon: ShareIcons.Telegram,
  //   name: 'telegram',
  //   url: generateAppSchema,// Telegram
  //   gtmEventName: sharedModalEvents.telegram.name
  // },
  {
    text: 'Facebook',
    icon: ShareIcons.FB,
    name: 'facebook',
    url: generateAppSchema,// facebookAppUrl
    gtmEventName: sharedModalEvents.facebook.name
  },
  {
    text: 'IG',
    name: 'instagram',
    icon: ShareIcons.IG,
    url: generateAppSchema, //instagram
    gtmEventName: sharedModalEvents.instagram.name
  },
  {
    text: 'X',
    icon: ShareIcons.TW,
    name: 'twitter',
    url: generateAppSchema,// twitter
    gtmEventName: sharedModalEvents.twitter.name
  },
  {
    text: 'Reddit',
    icon: ShareIcons.Reddit,
    name: 'reddit',
    url: generateAppSchema, //reddit
    gtmEventName: sharedModalEvents.reddit.name
  }
]