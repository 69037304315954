//改变font-size
;(function (doc, win) {
  var docEI = doc.documentElement,
    resizeEvt = 'orientationchange' in window ? 'orientataionchange' : 'resize',
    recalc = function () {
      var clientWidth = docEI.clientWidth
      if (!clientWidth) return
      const fontSize = 16 * (clientWidth / 750)
      docEI.style.fontSize = fontSize > 14 ? 14 + 'px' : fontSize + 'px'
    }

  if (!doc.addEventListener) return
  win.addEventListener(resizeEvt, recalc, false)
  doc.addEventListener('DOMContentLoaded', recalc, false)
})(document, window)
