import serverAxios from 'src/lib/server/index'

const serverSendEmailOTP = (payload: any) => {
  return serverAxios({
    url: '/authentication/email-otp/generate',
    method: 'post',
    data: payload
  })
}

export { serverSendEmailOTP }
