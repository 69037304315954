import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import Lottie from 'lottie-web'

export default function LuckyDrawPopup({ open, userList, acceptCallback }: any) {
  const container = useRef(null!)
  const [list, setList] = useState([])

  // const anotherList = useMemo(() => {
  //   if (open) {
  //     return userList
  //   }
  // }, [open])

  useEffect(() => {
    if (open) {
      setList(userList)
    }
  }, [open])

  useEffect(() => {
    const animation = Lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('./lucky.json'),
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    })

    // Lottie.play()
    return () => {
      animation.destroy()
    }
  }, [open])

  useEffect(() => {
    const scrollY = document.body.style.top

    if (open) {
      document.body.style.position = 'fixed'
      document.body.style.top = '0'
      document.body.style.bottom = '0'
      document.body.style.left = '0'
      document.body.style.right = '0'
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.position = 'static'
      document.body.style.overflow = 'auto'
      document.body.style.top = scrollY
    }
  }, [open])

  if (open) {
    return (
      <LuckyDrawPopupWrapped>
        <div ref={container} className='animate' />
        <div className='content'>
          <p className='lucky-tips'>
            <span className='num'>
              {list?.length < 99 ? list?.length : list?.length + '+'}
            </span>
            {list?.length > 1 ? (
              <span className='text'>lucky draw chances</span>
            ) : (
              <span className='text'>lucky draw chance</span>
            )}
          </p>
          <p className={`avatar-ct ${list.length <= 3 ? 'avatar-ct-normal' : ''}`}>
            {list.slice(0, Math.min(list.length, 3))?.map((item: any) => (
              <img
                key={item.participant_id}
                className='avatar'
                src={item.avatar}
                alt={item.avatar}
              />
            ))}
            {list.length > 3 ? (
              <span className='count'>
                {list?.length < 99 ? list?.length : list?.length + '+'}
              </span>
            ) : null}
          </p>
          <p className='status-tips'>Successfully referred</p>
        </div>

        <span className='btn' onClick={acceptCallback} />
      </LuckyDrawPopupWrapped>
    )
  }
  return null
}

const LuckyDrawPopupWrapped = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  background: rgba(0, 0, 0, 0.76);
  z-index: 5000;

  .animate {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0;
    transform: scale(1.3);
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    will-change: transform;
  }
  .content {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 4000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .lucky-tips {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 26px;
      font-weight: 700;

      .text {
        background: linear-gradient(90deg, #ffa413 5.46%, #fff6b0 97.08%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .num {
        margin-right: 7px;
        color: #ffa413;
        animation: anim 0.5s 0.3s;
      }
      @keyframes anim {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(0.5);
        }
        100% {
          transform: scale(1);
        }
      }
    }

    .status-tips {
      color: #fff;
      font-size: 18px;
    }
    .avatar-ct {
      display: flex;
      align-items: center;
      margin: 15px 0 10px;
      .count {
        display: flex;
        position: relative;
        z-index: 4;
        align-items: center;
        justify-content: center;
        margin-left: -10px;
        font-size: 12px;
        padding: 0 11px;
        font-weight: 500;
        height: 28px;
        border-radius: 27px;

        border: 1px solid #f7eece;
        background: #fff;
      }
      .avatar {
        position: relative;
        border-radius: 50%;
        width: 28px;
        height: 28px;
        margin-left: -10px;
        box-sizing: border-box;
        border: 1px solid #f7eece;
        &:nth-of-type(1) {
          margin-left: 0;
        }
        &:nth-of-type(2) {
          z-index: 1;
        }
        &:nth-of-type(3) {
          z-index: 2;
        }
      }
    }
    .avatar-ct-normal {
      .avatar {
        margin: 0 5px;

        &:nth-of-type(1) {
          margin-left: 5px;
        }
      }
    }
  }
  .btn {
    position: absolute;
    top: 65.5%;
    left: 50%;
    margin-left: -96px;
    width: 192px;
    height: 60px;
    font-weight: 700;
    font-size: 20px;
    z-index: 5001;
    overflow: hidden;
    background-image: url('/accept_btn_bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
`
