import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { fetchSgSpringEventMe } from 'src/api/sgSpringEvent'

type initialStateType = {
  fetching: boolean
  response: { [key: string]: any }
  error: any
}

const initialState: initialStateType = {
  fetching: false,
  response: {},
  error: null
}

export const sliceFetchSgSpringEventMe = createAsyncThunk(
  'sg-spring-event/me',
  async (payload, /*thunkAPI*/ { rejectWithValue }) => {
    try {
      const response = await fetchSgSpringEventMe()
      return response
    } catch ({ response }: any) {
      return rejectWithValue(response)
    }
  }
)

const SgSpringEventMe = createSlice({
  name: 'login',
  initialState,
  reducers: {
    dispatchLogin: (state, { payload }) => {
      //console.log(state, payload)
    }
  },
  extraReducers(builder) {
    builder
      .addCase(sliceFetchSgSpringEventMe.pending, (state) => {
        state.fetching = true
      })
      .addCase(sliceFetchSgSpringEventMe.fulfilled, (state, result) => {
        state.fetching = false
        state.response = result
      })
      .addCase(sliceFetchSgSpringEventMe.rejected, (state, result) => {
        state.error = result
        state.fetching = false
      })
  }
})

export const { dispatchLogin } = SgSpringEventMe.actions

export default SgSpringEventMe.reducer
