import { CircularProgress } from "@mui/material"
import { useEffect } from "react"
import { useNavigate, useNavigation } from "react-router-dom"

export const Jump = () => {
  const nav = useNavigate();

  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const referral_code = params.get('c')
    const redirect = `/new-year-boost-campaign/login${referral_code ? `?code=${referral_code}` : ""}`

    nav(redirect);
  }, [])
  return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
    <CircularProgress />
  </div>
}