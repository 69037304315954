import awardGoldImage from '../static/award/award-gold.png'
import awardIkeaImage from '../static/award/award-ikea.png'
import awardUniqloImage from '../static/award/award-uniqlo.png'
import awardStarbucksImage from '../static/award/award-starbucks.png'
import awardVoucherImage from '../static/award/award-voucher.png'

// import {
//   awardGoldImage,
//   awardIkeaImage,
//   awardUniqloImage,
//   awardStarbucksImage,
//   awardVoucherImage
// } from 'src/images'

interface AwardConfig {
  title: string
  message: string
  image: string
}

type GiftsType = {
  [key: string]: AwardConfig
}

export const giftConfig: GiftsType = {
  '1': {
    title: 'Your HUAT game damn strong lah! Spend your prize wisely for you and your loved ones.',
    message: 'You Won the Grand Prize $1,888 Cash',
    image: awardGoldImage
  },
  '2': {
    title: 'Your HUAT game good lah!  Time to level up with a room revamp.',
    message: 'You Won $50 Gift Card at IKEA',
    image: awardIkeaImage
  },
  '3': {
    title: 'Your HUAT game okay lah!  Give yourself a new OOTD look this year.',
    message: 'You Won $20 Gift Card at Uniqlo',
    image: awardUniqloImage
  },
  '4': {
    title: 'Your HUAT game okay lah! Relax a bit and have a coffee.',
    message: 'You Won$20 Gift Card at Starbucks',
    image: awardStarbucksImage
  },
  '5': {
    title: 'Your HUAT game not bad lah! Enjoy good food with your friends and family.',
    message: 'You Won $10 Voucher at Paradise Group Restaurants',
    image: awardVoucherImage
  }
}

export const checkAwardId = (id: string): boolean => {
  return Object.keys(giftConfig).includes(id)
}

export const getAwardConfig = (id: string): AwardConfig => {
  const _id: string = checkAwardId(id) ? id : '-1'
  return giftConfig[_id] || {}
}
