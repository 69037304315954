import shareContent from './shareContent.png'
import shareFooter from './shareFooter.png'

import shareContentJpg from './shareContent.jpg'
import shareFooterJpg from './shareFooter.jpg'

import icons from './Icons'
import svg from './shareContentSvg.svg'
import s2 from './shareContent2.png'
export const ShareIcons = icons
export const content = shareContent
export const footer = shareFooter



const S3BucketJobseeker = process.env.REACT_APP_S3_BUCKET_URL

export const shareContent1x = `${S3BucketJobseeker}/shareContent.png`
export const shareFooter1x = `${S3BucketJobseeker}/shareFooter.png`
// // s2 shareContent// 
const shareContent2x = shareContentJpg// // `${S3BucketJobseeker}/shareContent2x.png`
const shareFooter2x = shareFooterJpg// `${S3BucketJobseeker}/shareFooter2x.png`
// sharing img without radius
export const NewYearShareContent2x = shareContent2x
export const NewYearShareFooter2x = shareFooter2x
