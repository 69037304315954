// import down from './down.png'
// import FB from './Facebook.png'
// import FBMessage from './FB Messenger.png'
// import IG from './IG.png'
// import link from './link.png'
// import Reddit from './Reddit.png'
// import Telegram from './Telegram.png'
// import TW from './Twitter.png'
// import whatsApp from './Whatsapp.png'

const S3BucketJobseeker = process.env.REACT_APP_S3_BUCKET_URL

const down = `${S3BucketJobseeker}/down.png`
const FB = `${S3BucketJobseeker}/Facebook.png`
const FBMessage = `${S3BucketJobseeker}/FB Messenger.png`
const IG = `${S3BucketJobseeker}/IG.png`
const link = `${S3BucketJobseeker}/link.png`
const Reddit = `${S3BucketJobseeker}/Reddit.png`
const Telegram = `${S3BucketJobseeker}/Telegram.png`
const TW = `${S3BucketJobseeker}/Twitter.png`
const whatsApp = `${S3BucketJobseeker}/Whatsapp.png`

export default {
  down, FB, FBMessage, IG, link,
  Reddit, Telegram, TW, whatsApp
}