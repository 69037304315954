import { Suspense } from 'react'
import { useRoutes } from 'react-router-dom'
import RouterConfig from './lib/router'
import Loading from './components/Loading/Loading'
import 'src/lib/amfe-flexible/index'
import "src/configure.sentry"
import './App.css'

function App() {
  const element = useRoutes(RouterConfig)
  return (
    <div className='App'>
      <Suspense fallback={<Loading />}>{element}</Suspense>
    </div>
  )
}

export default App
