import { SpinLoading } from 'antd-mobile'
import styled from './index.module.scss'

export default function Loading() {
  return (
    <div className={styled.loadingContainer}>
      <div className={styled.loadingSpinner}>
        <SpinLoading color='primary' />
      </div>
    </div>
  )
}
