import { isAndroid } from 'react-device-detect'

export function locationReplace(url) {
  // if(history.replaceState){
  //   history.replaceState(null, document.title, url);
  //   history.go(0);
  // }else{
  //   location.replace(url);
  // }

  if (isAndroid) {
    // locationReplace(url)
    if (history.replaceState) {
      history.replaceState(null, document.title, url)
      history.go(0)
    } else {
      location.replace(url)
    }
  } else {
    window.location.replace(url)
  }
}
