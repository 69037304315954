import { setGoogleTagEvent } from 'src/utils'
import classNames from 'classnames'

import { appHomeEvents } from 'pages/new-year-boost-campaign/events'

import { NewYearBtnBg, NewYearChanceBg, NewYearHand, NewYearBtnDisabled } from 'src/images'

import styled from '../../index.module.scss'

// const TIME = '2024-02-26T00:00:00'

const ShareChances = ({
  lucky_draw_count,
  handleShowShareModelContentBtn,
  isAfterTargetDate,
  in_progress_count,
  is_new_user
}: any) => {
  const isFailed = !is_new_user && lucky_draw_count == 0

  return (
    <>
      <section className={styled.chancesWrapper}>
        <div className={styled.chances}>
          <div className={styled.numberWrapper}>
            <span className={styled.number}>{lucky_draw_count}</span>
            <div className={styled.chanceBox}>
              <span className={styled.write}>{lucky_draw_count > 1 ? 'Chances' : 'Chance'}</span>
              <img src={NewYearChanceBg} alt='chances background' className={styled.chanceBg} />
            </div>
          </div>

          <div className={styled.inviteText}>Refer friends to get more chances</div>

          <div className={styled.shareUrl}>
            <div
              className={classNames([
                styled.shareUrl_btn,
                isAfterTargetDate && isFailed ? styled.shareUrl_btnFailed : null
              ])}
              style={{
                backgroundImage:
                  isAfterTargetDate && isFailed
                    ? `url(${NewYearBtnDisabled})`
                    : `url(${NewYearBtnBg})`
              }}
              onClick={() => {
                if (isAfterTargetDate && isFailed) {
                  return
                }

                handleShowShareModelContentBtn()
                const { getMoreChancesButton, pendingPrizeButton } = appHomeEvents
                const eventName = !isAfterTargetDate
                  ? getMoreChancesButton.name
                  : pendingPrizeButton.name
                setGoogleTagEvent(eventName)
              }}
            >
              {!isAfterTargetDate
                ? 'Get More Chances'
                : isFailed
                ? 'Referral failed'
                : 'pending PRIZE'}

              {isAfterTargetDate && isFailed ? null : (
                <img className={styled.hand} src={NewYearHand} alt='little hand' />
              )}
            </div>
          </div>

          <div className={styled.shareUrlUnInvite}>
            {in_progress_count > 1 ? `${in_progress_count} friends` : `${in_progress_count} friend`}
            &nbsp; waiting to sign up
          </div>
          <div className={styled.overDate}>Lucky Draw Date: 27/02/2024</div>
        </div>
      </section>
    </>
  )
}

export default ShareChances
