import { useEffect, useRef, useState } from 'react'
import { Tabs } from 'antd-mobile'
import { useSearchParams } from 'react-router-dom'
import Lottie from 'lottie-web'

import { setGoogleTagEvent } from 'src/utils'
import { appHomeEvents } from 'pages/new-year-boost-campaign/events'

import { NewYearTicket, NewYearEmptyStateIcon } from 'src/images'

import styled from '../../index.module.scss'
import fireworkAnimation from '../../animation/fireworks.json'

const Item = ({ children, item }: any) => {
  return (
    <div className={styled.tabContent}>
      <div>
        <div className={styled.cellPhoneNumber}>{item.phone_num}</div>
        <div className={styled.february}>{item.participated_at}</div>
      </div>
      <div>{children}</div>
    </div>
  )
}

const Empty = ({ handleShowShareModel }: any) => {
  return (
    <div className={styled.empty}>
      <img src={NewYearEmptyStateIcon} alt='empty' />
      <p>You have no referrals yet.</p>
      <div
        className={styled.btn}
        onClick={() => {
          handleShowShareModel()
          setGoogleTagEvent(appHomeEvents.referNowButton.name)
        }}
      >
        Refer Now
      </div>
    </div>
  )
}

const ReferralHistory = ({
  in_progress_jobseekers,
  referred_jobseekers,
  handleShowShareModel
}: any) => {
  const rankRef = useRef(null)
  const firework = useRef<any>(null)

  const [searchParams] = useSearchParams()
  const [tabActive, setTabActive] = useState<string>('Referred')

  useEffect(() => {
    const tabVal = searchParams.get('tab')
    if (tabVal && (tabVal == 'Referred' || tabVal == 'InProgress')) {
      setTabActive(tabVal)

      if (rankRef.current) {
        ;(rankRef.current as HTMLElement).scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [searchParams, rankRef.current])

  useEffect(() => {
    const animation = Lottie.loadAnimation({
      container: firework.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: fireworkAnimation
    })

    // Lottie.play()
    return () => {
      animation.destroy()
    }
  }, [])

  const handleFashionSwitchTab = (e: string | null) => {
    if (e) {
      setTabActive(e)
      setGoogleTagEvent(
        e === 'Referred' ? appHomeEvents.referredTab.name : appHomeEvents.inProgressTab.name
      )
    }
  }

  return (
    <section className={styled.rankWrapper} ref={rankRef}>
      <div className={styled.fireworks} ref={firework}></div>

      <div className={styled.referralHistory}>
        <div className={styled.rankContent}>
          <div className={styled.title}>
            <span>Referral History</span>
          </div>
        </div>

        <div className={styled.tab}>
          <Tabs activeKey={tabActive} onChange={handleFashionSwitchTab}>
            <Tabs.Tab title='Referred' key='Referred'>
              {referred_jobseekers?.length ? (
                referred_jobseekers.map((item: any, index: number) => (
                  <Item key={index} item={item}>
                    <img src={NewYearTicket} alt='ticket' width={'32px'} />
                  </Item>
                ))
              ) : (
                <Empty handleShowShareModel={handleShowShareModel} />
              )}
            </Tabs.Tab>
            <Tabs.Tab title='In Progress' key='InProgress'>
              {in_progress_jobseekers?.length ? (
                in_progress_jobseekers.map((item: any, index: number) => (
                  <Item key={index} item={item}>
                    <div
                      className={styled.notification}
                      onClick={() => {
                        handleShowShareModel()
                        setGoogleTagEvent(appHomeEvents.notifyButton.name)
                      }}
                    >
                      Notify
                    </div>
                  </Item>
                ))
              ) : (
                <Empty handleShowShareModel={handleShowShareModel} />
              )}
            </Tabs.Tab>
          </Tabs>
        </div>
      </div>
    </section>
  )
}

export default ReferralHistory
