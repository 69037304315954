import { useEffect, useRef } from 'react'
import Lottie from 'lottie-web'

import lanternsFluttering from '../../animation/lanternsFluttering.json'
import firework from '../../animation/fireworks.json'

import styled from '../../index.module.scss'

import { NewYearBanner, NewYearBanner2x, NewYearStartBg } from 'src/images'

const BgImage = () => {
  const lanternLeft = useRef<any>(null)
  const lanternRight = useRef<any>(null)
  const fireworkLeft = useRef<any>(null)
  const fireworkRight = useRef<any>(null)

  useEffect(() => {
    const animation = Lottie.loadAnimation({
      container: lanternLeft.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: lanternsFluttering
    })

    // Lottie.play()
    return () => {
      animation.destroy()
    }
  }, [])

  useEffect(() => {
    const animation = Lottie.loadAnimation({
      container: lanternRight.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: lanternsFluttering
    })

    // Lottie.play()
    return () => {
      animation.destroy()
    }
  }, [])

  useEffect(() => {
    let animation: any = null
    const atTheMoment = setTimeout(() => {
      animation = Lottie.loadAnimation({
        container: fireworkLeft.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: firework
      })
    }, 1000)

    // Lottie.play()
    return () => {
      animation.destroy()
      clearTimeout(atTheMoment)
    }
  }, [])

  useEffect(() => {
    const animation = Lottie.loadAnimation({
      container: fireworkRight.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: firework
    })

    // Lottie.play()
    return () => {
      animation.destroy()
    }
  }, [])

  return (
    <section className={styled.mainBanner}>
      <div className={styled.fireworks_left} ref={fireworkLeft}></div>
      <div className={styled.fireworks_right} ref={fireworkRight}></div>
      <div className={styled.startBg}>
        <img src={NewYearStartBg} alt='start bg' />
      </div>
      {/* <img src={banner} alt='banner' className={styled.banner} /> */}
      <img
        className={styled.banner}
        src={NewYearBanner}
        alt='banner'
        srcSet={`${NewYearBanner} 1x, ${NewYearBanner2x} 2x`}
      />

      <div className={styled.lantern_left} ref={lanternLeft}></div>
      <div className={styled.lantern_right} ref={lanternRight}></div>
    </section>
  )
}

export default BgImage
