// google tag manager events

// key: event action
// value: event values

// h5注册页
export const loginEvents = {
  'pageView': {name: "registration_page"},
  'pageViewInApp': {name: "registration_page_inapp"},
  'singUpButton': {name: 'registration_page_singup'},
  'singUpInAppButton': {name: 'registration_page_singup_inapp'},
  'getStartedButton': {name: 'registration_page_get_started'},
  'downloadNowButton': {name: 'registration_page_download_now'},
  'rulesButton': {name: 'registration_page_rules'}
} as const

// h5客态页
export const customerPageEvents = {
  'acceptButton': {name: 'customer_page_accept'},
  'closeButton': {name: 'customer_page_close'}
} as const

// app内h5主态页
export const appHomeEvents = {
  'pageView': {name: "main_activity_page"},
  'getMoreChancesButton': {name: 'main_get_more_chances'},
  'pendingPrizeButton': {name: 'main_pending_prize'},
  'referNowButton': {name: 'main_refer_now'},
  'rulesButton': {name: 'main_rules'},
  'referredTab': {name: 'main_referred'},
  'inProgressTab': {name: 'main_in_progress'},
  'notifyButton': {name: 'main_notify'},
  'acceptButton': {name: 'main_accept'} // 主态有人助力弹窗
} as const

// 分享弹窗
export const sharedModalEvents = { 
  'download': {name: 'share_download'},
  'copyLink': {name: 'share_copy_link'},
  'whatsapp': {name: 'share_whatsapp'},
  'fbMessenger': {name: 'share_fb_messenger'},
  'telegram': {name: 'share_telegram'},
  'facebook': {name: 'share_facebook'},
  'instagram': {name: 'share_instagram'},
  'twitter': {name: 'share_twitter'},
  'reddit': {name: 'share_reddit'}
} as const

// 中奖页
export const rewardEvents = {
  'pageView': {name: "reward_page"},
  'submitButton': {name: 'reward_page_submit'}
} as const