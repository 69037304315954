import moment from 'moment'
import Cookies from 'universal-cookie'

export const userKey = 'user'
export const refreshToken = 'refreshToken'
export const accessToken = 'accessToken'

const cookies = new Cookies()
export const setCookie = (cookieName: any, data: any, expireTime: any) => {
  cookies.set(cookieName, data, {
    path: '/',
    expires: expireTime ? new Date(expireTime) : new Date(moment().add(1, 'M').utc().format())
  })
}

export const getCookie = (cookieName: string) => {
  return cookies.get(cookieName)
}

export const removeCookie = (cookieName: string) => {
  cookies.remove(cookieName, { path: '/' })
}

export const setAccessToken = (value: string, expireTime: string) => {
  setCookie(accessToken, value, expireTime)
}

/**
 * remove the user's data in browser
 */
export const removeUserCookie = () => {
  removeCookie(accessToken)
  removeCookie(refreshToken)
  removeCookie(userKey)
}
