import { useCallback, useEffect, useState } from 'react'
import { SpinLoading } from 'antd-mobile'
import { Link, useSearchParams } from 'react-router-dom'
import { getCookie, setCookie } from 'src/utils/cookies'

import BgImage from './components/BgImage'
import ShareChances from './components/ShareChances'
import ReferralHistory from './components/Referralhistory'

import LuckyDrawPopup from 'src/components/Popup/LuckyDrawPopup'
import OpenRuleModal from 'src/components/Popup/RulePopup'
import ReferWinPopup from 'src/components/Popup/ReferWinPopup'
import { Share } from 'pages/new-year-boost-campaign/components/share'

import { fetchSgSpringEventViewReferredJobseekers } from 'src/api/sgSpringEvent'

import useSgSpring from '../hook/useSgSpring'
import useSgShare from '../hook/useSgShare'

import { getDeviceUuid, setGoogleTagEvent } from 'src/utils/index'
import { appHomeEvents, customerPageEvents } from '../events'
import { recordEnterEventPage } from 'src/api/sgSpringEvent'

import styled from './index.module.scss'

import { NewYearWhenNewYearInviteStart } from 'src/images'

const Home = () => {
  const [searchParams] = useSearchParams()
  const isShowHelpOtherBody = getCookie('newYearInviteCampaignHelpOtherBody') || false

  const [luckOpen, setLuckOpen] = useState<boolean>(false)
  const [rulesOpen, setRulesOpen] = useState<boolean>(false)
  const [helpOtherBodyOpen, setHelpOtherBodyOpen] = useState<boolean>(false)
  const [list, setList] = useState<[] | null>([])
  const [isShowInvite, setIsShowInvite] = useState<boolean>(false)

  const { fetching, firstTime, response, referral_code, userState } = useSgSpring()
  const { data = {} } = response?.payload || {}
  const {
    unread_referral_history,
    referral_history,
    lucky_draw_result,
    refer_by,
    event_detail,
    is_new_user
  } = data || {}
  const {
    in_progress_jobseekers = [],
    referred_jobseekers = [],
    in_progress_count = 0
  } = referral_history || {}
  // eslint-disable-next-line prefer-const
  const { count = null, jobseekers = [] } = unread_referral_history || {}
  const { lucky_draw_count = 0 } = lucky_draw_result || {}
  const { referrer_full_name = null, is_lucky_draw_entry = null } = refer_by || {}
  const { ended_at = null } = event_detail || {}

  const {
    showShare,
    setShowShare,
    isAfterTargetDate,
    handleShowShareModel,
    handleShowShareModelContentBtn
  } = useSgShare(ended_at)

  useEffect(() => {
    setGoogleTagEvent(appHomeEvents.pageView.name)
  }, [])

  useEffect(() => {
    if (data?.phone_num) {
      recordEnterEventPageFun(data)
    }
  }, [data?.phone_num])

  useEffect(() => {
    const isShare = searchParams.get('share')
    if (isShare && isShare == 'true') {
      setShowShare(true)
    }
  }, [searchParams])

  // useEffect(() => {
  //   if (luckOpen) {
  //     return
  //   }

  //   // 帮他人助力
  //   if (is_lucky_draw_entry && referrer_full_name && !isShowHelpOtherBody) {
  //     setHelpOtherBodyOpen(true)
  //     const timeName = setTimeout(() => {
  //       setCookie('newYearInviteCampaignHelpOtherBody', true, false)
  //     }, 5000)

  //     return () => {
  //       clearTimeout(timeName)
  //     }
  //   }
  // }, [referrer_full_name, is_lucky_draw_entry, isShowHelpOtherBody, luckOpen])

  // useEffect(() => {
  //   let whatNameIsTime: any = null
  //   // 他人帮你助力
  //   if (count && jobseekers.length) {
  //     setLuckOpen(true)
  //     whatNameIsTime = setTimeout(() => {
  //       handleViewReferredJobseekers()
  //     }, 5000)
  //   } else {
  //     setLuckOpen(false)
  //   }

  //   return () => {
  //     if (whatNameIsTime) clearTimeout(whatNameIsTime)
  //   }
  // }, [jobseekers, count])

  useEffect(() => {
    if (count && jobseekers.length) {
      setList(jobseekers)
    }
  }, [count, jobseekers])

  useEffect(() => {
    if (is_lucky_draw_entry && referrer_full_name && !isShowHelpOtherBody) {
      setIsShowInvite(true)
    } else {
      setIsShowInvite(false)
    }
  }, [is_lucky_draw_entry, referrer_full_name, isShowHelpOtherBody])

  useEffect(() => {
    let myselfTime: any = null
    let otherOneTime: any = null

    if (luckOpen) {
      // 他人帮你助力的弹窗已经出现了
      return
    }

    if (helpOtherBodyOpen) {
      // 你帮别人助力的弹窗已经出现了
      return
    }

    // 他人帮你助力
    if (list && list.length) {
      setLuckOpen(true)
      handleViewReferredJobseekers()
      otherOneTime = setTimeout(() => {
        setLuckOpen(false)
      }, 6000)

      return
    }

    // 帮他人助力
    if (isShowInvite) {
      setHelpOtherBodyOpen(true)
      setCookie('newYearInviteCampaignHelpOtherBody', true, false)
      myselfTime = setTimeout(() => {
        setHelpOtherBodyOpen(false)
      }, 6000)
      return
    }

    return () => {
      if (otherOneTime) clearTimeout(otherOneTime)

      if (myselfTime) clearTimeout(myselfTime)
    }
  }, [list, isShowInvite, luckOpen, helpOtherBodyOpen])

  const recordEnterEventPageFun = async (data: any) => {
    const deviceUuid = await getDeviceUuid()
    const { phone_num, referral_code } = data || {}
    recordEnterEventPage({
      udid: deviceUuid,
      source: 'mobile_web',
      phone_num,
      referral_code
    }).catch((e) => console.log(e))
  }

  const handleViewReferredJobseekers = () => {
    try {
      setGoogleTagEvent(appHomeEvents.acceptButton.name)
      const participantIds = jobseekers.map((item: any) => item.participant_id)?.toString()
      fetchSgSpringEventViewReferredJobseekers({ participant_ids: participantIds }).then(
        ({ code, message }: any) => {
          if (code == 0 && message == 'Success') {
            setList([])
          }
        }
      )
    } catch (error) {
      //
    }
  }

  const handleSaveHelpOtherBody = (type: string) => {
    setCookie('newYearInviteCampaignHelpOtherBody', true, false)
    setHelpOtherBodyOpen(false)
    setGoogleTagEvent(
      type === 'accept' ? customerPageEvents.acceptButton.name : customerPageEvents.closeButton.name
    )
  }
  const sharingUrl = `${window.location.origin}/Ju?c=${encodeURIComponent(referral_code)}`
  const closeShare = useCallback(() => setShowShare(false), [])
  if ((fetching && !firstTime) || !response || !userState) {
    return (
      <div className={styled.pageLoading}>
        <SpinLoading color='primary' />

        {/* <button onClick={() => {
          setShowShare(true)
        }}>aaa</button>
        <Share show={showShare} text={sharingUrl} onCancel={() => setShowShare(false)} /> */}
      </div>
    )
  }

  return (
    <>
      <main className={styled.main}>
        <div className={styled.wrapper}>
          <BgImage />

          {/* <div className={styled.placeholderBox}></div> */}

          <div className={styled.head}>
            <img
              src={NewYearWhenNewYearInviteStart}
              alt='What time begin and end'
              className={styled.whatTimeBeginAndEnd}
            />

            <div
              className={styled.rules}
              onClick={() => {
                setRulesOpen(true)
                setGoogleTagEvent(appHomeEvents.rulesButton.name)
              }}
            >
              T&Cs
            </div>
          </div>

          <ShareChances
            referral_code={referral_code}
            lucky_draw_count={lucky_draw_count}
            showShare={showShare}
            setShowShare={setShowShare}
            isAfterTargetDate={isAfterTargetDate}
            handleShowShareModelContentBtn={handleShowShareModelContentBtn}
            in_progress_count={in_progress_count}
            is_new_user={is_new_user}
          />

          <ReferralHistory
            in_progress_jobseekers={in_progress_jobseekers}
            referred_jobseekers={referred_jobseekers}
            handleShowShareModel={handleShowShareModel}
          />
        </div>

        {/* 他人帮你助力 */}
        <LuckyDrawPopup
          open={luckOpen}
          acceptCallback={() => {
            setLuckOpen(false)
          }}
          userList={list}
        />

        <OpenRuleModal
          open={rulesOpen}
          onClose={() => {
            setRulesOpen(false)
          }}
        />

        {/* 帮他人助力 */}
        <ReferWinPopup
          open={helpOtherBodyOpen}
          onClose={() => handleSaveHelpOtherBody('close')}
          userName={referrer_full_name}
          acceptCallback={() => handleSaveHelpOtherBody('accept')}
        />
      </main>
      <Share show={showShare} text={sharingUrl} onCancel={closeShare} />
    </>
  )
}

export default Home
