import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Toast } from 'antd-mobile'
import { fetchSgSpringGenerateCode, fetchSgSpringVerifyCode } from 'src/api/sgSpringEvent'
import styles from './index.module.scss'
import scrollIntoView from 'smooth-scroll-into-view-if-needed'
import { setGoogleTagEvent } from 'src/utils'
import { rewardEvents } from '../events'


// eslint-disable-next-line react-hooks/exhaustive-deps
const reg = /^[\w-\\.-_]+@([\w-]+\.)+[\w-]+$/

const Form = (props: {id: string}) => {
  const { id } = props
  const timerId = React.useRef<any>(null)
  const formRef = React.useRef<any>(null)
  const [formValues, setFormValues] = useState({ email: '', code: '' })

  const canSendCode = !!(reg.test(formValues.email))
  const enabled = (formValues.code.length > 3) && canSendCode

  const [counter, setCounter] = useState(0)
  const navigate = useNavigate()

  const toastMessage = (message: string) => {
    if(!message) return
    Toast.show({
      content: <span className={styles.toastMessage}>{message}</span>
    })
  }

  const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    setGoogleTagEvent(rewardEvents.submitButton.name)
    if (!enabled) return
    fetchSgSpringVerifyCode({otp: formValues.code}).then((res: any) => {
      if(res?.code === 0) {
        navigate(`/new-year-boost-campaign/${id}/success?email=${formValues.email}`, { replace: true })
      }
    }).catch((error: any) => {
      const response = error?.response?.response?.data || {message: 'something went wrong, please refresh and try again'}
      toastMessage(response?.message)
    })
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleScrollToView = () => {
    setTimeout(() => {
      scrollIntoView(formRef?.current, {
        scrollMode: 'if-needed'
      })
    }, 300)
  }

  const sendCode = () => {
    if(!canSendCode) return
    fetchSgSpringGenerateCode({ email: formValues.email }).then((res: any) => {
      if(res?.code === 0) {
        toastMessage('Confirmation code sent')
        setCounter(60)
      }
    }).catch((error: any) => {
      const response = error?.response?.response?.data || {message: 'something went wrong, please refresh and try again'}
      toastMessage(response?.message)
    })
  }

  const clear = () => {
    window.clearInterval(timerId.current)
  }

  useEffect(() => {
    counter > 0 && (timerId.current = window.setTimeout(() => setCounter(counter - 1), 1000))
    return () => clear()
  }, [counter])



  return (
    <form className={styles.form} onSubmit={onSubmit} ref={formRef}>
      <label htmlFor='email' className={styles.formLabel}>
        <input
          autoComplete='off'
          name='email'
          onChange={handleInputChange}
          onFocus={handleScrollToView}
          value={formValues.email}
          className={styles.formInput}
          type='email'
          placeholder='Enter Email'
        />
      </label>
      <label htmlFor='code' className={styles.formLabel}>
        <input
          autoComplete='off'
          name='code'
          onChange={handleInputChange}
          onFocus={handleScrollToView}
          value={formValues.code}
          className={styles.formInput}
          style={{ width: '70%' }}
          type='text'
          maxLength={10}
          placeholder='Enter Confirmation Code'
        />
        <span className={styles.sendCode + ' ' + (canSendCode ? '' : styles.disabled)} onClick={sendCode}>
          {counter > 0 ? counter + 's' : 'Send Code'}
        </span>
      </label>
      <button
        disabled={!enabled}
        className={styles.formSubmit + ' ' + (enabled ? styles.formEnabled : '')}
        type='submit'
      ></button>
    </form>
  )
}

export default Form
