import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Toast } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'

import { fetchSgSpringEventParticipateAndEnjoyIt } from 'src/api/sgSpringEvent'
import { sliceFetchSgSpringEventDetail } from 'src/store/sgSpringEvent/detail'
import { getCookie } from 'src/utils/cookies'
import { locationReplace } from 'src/pages/new-year-boost-campaign/utils'

const ERROR_MESSAGE = 'The interface is abnormal, please exit and re-enter.'

/**
 * @description 主要用户判断用户当前状态，然后跳转对应的页面
 * @returns
 */
const useSgSpring = () => {
  const navigate = useNavigate()
  const timerRef = useRef<any>(null)
  const dispatch = useDispatch()
  const [firstTime, setFirstTime] = useState<boolean>(false)
  const [userState, setUserState] = useState<boolean>(false)

  const { fetching, response, error } = useSelector((store) => (store as any).sgSpringEventDetail)
  const { referral_code = null } = response?.payload?.data || {}

  const cleanup = () => {
    clearInterval(timerRef.current)
  }

  useEffect(() => {
    if (fetching) {
      setFirstTime(true)
    }
  }, [fetching])

  useEffect(() => {
    clearInterval(timerRef.current)
    timerRef.current = setInterval(() => {
      dispatch(sliceFetchSgSpringEventDetail())
    }, 1000 * 15)

    window.addEventListener('beforeunload', cleanup)

    return () => {
      cleanup()
      window.removeEventListener('beforeunload', cleanup)
    }
  }, [dispatch, sliceFetchSgSpringEventDetail])

  useEffect(() => {
    if (!response && !error) {
      dispatch(sliceFetchSgSpringEventDetail())
      return
    }
  }, [dispatch, response, error])

  useEffect(() => {
    if (error && error.type) {
      privateHandleShowErrorMessageToUser(error)
    }
  }, [error])

  useEffect(() => {
    if (!response) {
      return
    }

    const {
      payload: { data }
    } = response

    const {
      is_participated,
      mobile_country_id,
      is_mobile_verified,
      lucky_draw_result,
      redeem_prize_email
    } = data
    const { is_result_published, is_winner, prize } = lucky_draw_result || {}

    // 以参加
    if (is_participated && mobile_country_id == 193 && is_mobile_verified) {
      // 以参加活动，活动进行中跳转主页。结束跳转结束页面
      if (is_result_published && is_winner) {
        // 以开奖跳转开奖页面
        const { id } = prize || {}
        if (!redeem_prize_email) {
          // navigate(`/new-year-boost-campaign/${id}/submit`)
          // window.location.replace(`/new-year-boost-campaign/${id}/submit`)
          locationReplace(`/new-year-boost-campaign/${id}/submit`)
        } else {
          // navigate(`/new-year-boost-campaign/${id}/success?email=${redeem_prize_email}`)
          // window.location.replace(
          //   `/new-year-boost-campaign/${id}/success?email=${redeem_prize_email}`
          // )
          locationReplace(`/new-year-boost-campaign/${id}/success?email=${redeem_prize_email}`)
        }
      } else {
        // 没有开奖进入活动主页
        setUserState(true)
      }

      return
    }

    // 以参加但是中途修改手机号码
    if (is_participated && (mobile_country_id != 193 || !is_mobile_verified)) {
      //
      // window.location.replace(`/new-year-boost-campaign/login`)
      locationReplace(`/new-year-boost-campaign/login`)
    }

    // 未参加
    if (!is_participated) {
      if (mobile_country_id == 193 && is_mobile_verified) {
        // 未参加活动但是全都符合条件，直接调用接口参加活动
        privateHandlePackYourBagsLesUsGoJoinParty()
      } else {
        // navigate('/new-year-boost-campaign/login')
        // window.location.replace(`/new-year-boost-campaign/login`)
        locationReplace(`/new-year-boost-campaign/login`)
      }
    }
  }, [response])

  const privateHandleRefreshUserDetail = () => {
    dispatch(sliceFetchSgSpringEventDetail())
  }

  const privateHandleShowErrorMessageToUser = (error: any) => {
    let message = null
    try {
      message = error.payload.response.data.message
    } catch (error) {
      message = ERROR_MESSAGE
    }

    Toast.show({
      content: message
    })
  }

  const privateHandlePackYourBagsLesUsGoJoinParty = () => {
    const refreshToken = getCookie('refreshToken')
    fetchSgSpringEventParticipateAndEnjoyIt({ referral_code: refreshToken })
      .then(({ code, message }: any) => {
        if (code == 0 && message == 'Success') {
          privateHandleRefreshUserDetail()
        }
      })
      .catch((error: any) => {
        privateHandleShowErrorMessageToUser({ payload: error?.response || {} })
      })
  }

  const handleUninstallWebPage = () => {
    cleanup()
  }

  window.BossJobH5 = {
    handleUninstallWebPage
  }

  return {
    fetching,
    response,
    referral_code,
    firstTime,
    userState
  }
}

export default useSgSpring
