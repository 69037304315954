import React, { useState } from 'react'
import OpenRuleModal from 'src/components/Popup/RulePopup'
import styles from './index.module.scss'

const Rules = () => {

  const [openRules, setOpenRules] = useState(false)

  const openRulesHandler = () => {
    setOpenRules(true)
  }

  return (
    <>
      <div className={styles.rules} onClick={openRulesHandler}>T&Cs</div>
      <OpenRuleModal open={openRules} onClose={() => setOpenRules(false)} />
    </>
  )
}

export default Rules