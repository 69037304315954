import { configureStore } from '@reduxjs/toolkit'
import loginSlice from './festures/loginSlice'
import sgSpringEventDetail from './sgSpringEvent/detail'
import sgSpringEventMe from './sgSpringEvent/me'

const store = configureStore({
  reducer: {
    loginSlice,
    sgSpringEventDetail,
    sgSpringEventMe
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      //关闭序列化状态检测中间件
      serializableCheck: false
    }),
  devTools: true
})

export default store
