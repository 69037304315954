import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { serverSendEmailOTP } from 'src/api'

type initialStateType = {
  fetching: boolean
  response: { [key: string]: any }
  error: any
}

const initialState: initialStateType = {
  fetching: false,
  response: {},
  error: null
}

export const sliceServerSendEmailOTP = createAsyncThunk(
  'login/ServerSendEmailOTP',
  async (payload: { email: string }, /*thunkAPI*/ { rejectWithValue }) => {
    try {
      const response = await serverSendEmailOTP(payload)
      return response
    } catch ({ response }: any) {
      return rejectWithValue(response)
    }
  }
)

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    dispatchLogin: (state, { payload }) => {
      //console.log(state, payload)
    }
  },
  extraReducers(builder) {
    builder
      .addCase(sliceServerSendEmailOTP.pending, (state) => {
        state.fetching = true
      })
      .addCase(sliceServerSendEmailOTP.fulfilled, (state, result) => {
        state.fetching = false
        state.response = result
      })
      .addCase(sliceServerSendEmailOTP.rejected, (state, result) => {
        state.error = result
        state.fetching = false
      })
  }
})

export const { dispatchLogin } = loginSlice.actions

export default loginSlice.reducer
