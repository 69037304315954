self.onmessage = (e) => {
  const { imageData, dpr } = e.data;
  if (imageData) {
    const offscreenCanvas = new OffscreenCanvas(imageData.width, imageData.height);
    const ctx = offscreenCanvas.getContext('2d');

    ctx.scale(dpr, dpr)
    ctx.putImageData(imageData, 0, 0);
    offscreenCanvas?.convertToBlob().then(blob => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);

      reader.onload = (event) => {
        const data = event.target.result;
        self.postMessage(data);
      }
    });
  }
};