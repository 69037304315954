import serverAxios from 'src/lib/server/index'

const fetchSgSpringEventDetail = () => {
  return serverAxios({
    url: '/jobseekers/sg-spring-event/detail',
    method: 'GET'
  })
}

const fetchSgSpringEventMe = () => {
  return serverAxios({
    url: '/jobseekers/me',
    method: 'GET'
  })
  // return serverAxios.get('/jobseekers/me')
}

const fetchSgSpringEventParticipateAndEnjoyIt = (payload: any) => {
  return serverAxios({
    url: '/jobseekers/sg-spring-event/participate',
    method: 'POST',
    data: payload
  })
}

const fetchSgSpringEventViewReferredJobseekers = (payload: any) => {
  return serverAxios({
    url: '/jobseekers/sg-spring-event/view-referred-jobseekers',
    method: 'POST',
    data: payload
  })
}

const getPhoneOtp = (data: any) => {
  return serverAxios.post('/authentication/phone-otp/generate', data)
}

const jobseekersLogin = (data: any) => {
  return serverAxios.post('/authentication/jobseekers/login', data)
}

const fetchSgSpringGenerateCode = (payload: { email: string }) => {
  const url = '/authentication/email-otp/sg-spring-event-redeem-prize/generate'
  return serverAxios({
    url: url,
    method: 'POST',
    headers: {
      'Country-Id': 193, // sg
      'Role-Id': 1
    },
    data: payload
  })
}

const fetchSgSpringVerifyCode = (payload: { otp: string }) => {
  const url = '/authentication/email-otp/sg-spring-event-redeem-prize/verify'
  return serverAxios({
    url: url,
    method: 'POST',
    headers: {
      'Country-Id': 193, // sg
      'Role-Id': 1
    },
    data: payload
  })
}
const getGenerateOtp = (data: any) => {
  return serverAxios.post('/authentication/sms-otp/change-phone-number/generate', data)
}

const changePhone = (data: any) => {
  return serverAxios.post('/authentication/change-phone-number', data)
}


const getParticipate = (data: any) => {
  return serverAxios.post('/jobseekers/sg-spring-event/participate', data)
}

const recordEnterEventPage = (data: any) => {
  return serverAxios.post('/jobseekers/sg-spring-event/record-enter-event-page', data)
}



export {
  fetchSgSpringEventDetail,
  fetchSgSpringEventMe,
  fetchSgSpringGenerateCode,
  fetchSgSpringVerifyCode,
  getPhoneOtp,
  jobseekersLogin,
  fetchSgSpringEventParticipateAndEnjoyIt,
  getGenerateOtp,
  getParticipate,
  changePhone,
  fetchSgSpringEventViewReferredJobseekers,
  recordEnterEventPage
}
